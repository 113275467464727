export default () => {

  $(".simple_form .clear").on("click", (e) => {
    e.preventDefault();

    $(".simple_form input[type=text]").each(function() {
      $(this).val("");
    });

    $(".simple_form input[type=checkbox]").each(function() {
      $(this).prop("checked", false);
    });

    $(".simple_form select").each(function() {
      $(this)
        .not(".form-select")
        .val(null)
        .trigger("change");
    });

    $(".form-select").each(function() {
      $(this).val([]);
    });

  });

}
