import "select2";
import "select2/dist/js/i18n/pt-BR";

export default function() {

  const SEPARATOR = "&&";

  // para funcionar dentro do bootstrap modal
  $("select")
    .not(".form-select")
    .not(".form-select")
    .not(".select2_ajax")
    .not(".search select").each(function() {

      const dropdownParent = $(this).data("parent-modal");

      var opts = {
        placeholder: "Selecione",
        allowClear: true,
        theme: "bootstrap",
        escapeMarkup(markup) { return markup; },
        templateResult(item) {
          return renderResult(...item.text.split(SEPARATOR))
        },
        templateSelection(item) {
          return renderSelection(...item.text.split(SEPARATOR))
        }
      }

      if(dropdownParent) {
        opts['dropdownParent'] = $(dropdownParent)
      }

      $(this).select2(opts);
    });


  $(".search select")
    .not(".form-select")
    .select2({
      placeholder: "Selecione",
      allowClear: true
    });

  $("select.select2_ajax").each(function() {
    ajaxInit($(this));
  });

  $("[data-initial-option]").each(function() {
    fillInitialOption($(this));
  });

};

const ajaxInit = function(el) {
  const c = Object.assign({}, ajaxConfigs); // dup

  if (el.data("parent-modal")) {
    c.dropdownParent = $(el.data("parent-modal"));
  }

  c.ajax.url = el.data("url")

  el.select2(c);
};

const ajaxConfigs = {
  language: "pt-BR",
  allowClear: true,
  theme: "bootstrap",
  minimumInputLength: 2,
  placeholder: 'Selecione',
  width: "100%",
  ajax: {
    dataType: "json",
    delay: 250,
    cache: true,
    processResults(data, params) {
      return { results: data.items };
    }
  },

  escapeMarkup(markup) { return markup; },

  templateResult(item) {
    return renderResult(item.text, item.sub)
  },

  templateSelection(item) {
    return renderSelection(item.text, item.sub)
  }
};

const renderResult = (text, sub) => {
  if (sub) {
    return `${text}<span class='select2-sub'>${sub}</span>`;
  } else {
    return text;
  }
}

const renderSelection = (text, sub) => {
  if (sub) {
    return `<b>${text}</b> &nbsp; <small style='color: #888'>
              ${sub}
            </small>`;
  } else {
    return text;
  }
}

const fillInitialOption = function(select) {
  const option = select.data("initial-option");

  if (option.id) {
    const text = ajaxConfigs.templateSelection(option);
    const opt = new Option(text, option.id, true, true);

    select
      .append(opt)
      .trigger("change");
  }
}

document.addEventListener("turbolinks:before-cache", () =>
  $("select").each(function() {
    if ($(this).hasClass("select2-hidden-accessible"))
      $(this).select2("destroy");
  })
);
